@import "normalize.css";
@import url("https://fonts.googleapis.com/css2?family=PT+Sans&display=swap");

@import "~antd/lib/style";
@import "~antd/lib/layout/style";
@import "~antd/lib/spin/style";
@import "~antd/lib/button/style";
@import "~antd/lib/typography/style";
@import "~antd/lib/checkbox/style";
@import "~antd/lib/table/style";
@import "~antd/lib/select/style";
@import "~antd/lib/modal/style";
@import "~antd/lib/pagination/style";
@import "~antd/lib/dropdown/style";
@import "~antd/lib/menu/style";

body {
  font-family: "PT Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

#root,
.ant-spin-nested-loading,
.ant-spin-container,
.ant-layout {
  height: 100%;
}
